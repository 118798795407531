<template>
  <figure class="highcharts-figure">
    <highcharts ref="highchart" v-bind:options="chartOptions"/>
  </figure>
</template>

<script>
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import sankey from 'highcharts/modules/sankey';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';

export default {
  props: {
    steps: {
      required: true,
      type: Array,
    },
  },
  components: {
    highcharts: Chart,
  },
  computed: {
    chartOptions() {
      return {
        title: {
          text: '',
        },
        accessibility: {
          point: {
            valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.',
          },
        },
        tooltip: {
          nodeFormat: '{point.name}: <b>{point.sum}%</b><br/>',
          pointFormat: '{point.fromNode.name} → {point.toNode.name}: <b>{point.weight}%</b><br/>',
        },
        lang: {
          downloadCSV: this.$t('chart.export.download-csv'),
          downloadJPEG: this.$t('chart.export.download-jpeg'),
          downloadPDF: this.$t('chart.export.download-pdf'),
          downloadPNG: this.$t('chart.export.download-png'),
          downloadSVG: this.$t('chart.export.download-svg'),
          viewFullscreen: this.$t('chart.export.view-full-screen'),
          printChart: this.$t('chart.export.print-chart'),
        },
        chart: {
          type: 'sankey',
        },
        series: [{
          visible: true,
          keys: ['from', 'to', 'weight'],
          data: this.steps,
          type: 'sankey',
        }],
      };
    },
  },
  created() {
    sankey(Highcharts);
    exporting(Highcharts);
    offlineExporting(Highcharts);
  },
};
</script>


<style scoped lang='scss'>
  @import '~styles/components/_bot_graph.scss';
</style>
